.Toastify__toast {
    border-radius: 8px;
    font-family: Urbanist, Helvetica;
    padding: 10px;
    color: #fff;
    box-shadow: 0px 0px 6px 0px #0000001F;
}

.Toastify__toast--success {
    background-color: #ecfdf5;
    color: #065f46;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.Toastify__toast--error {
    background-color: #fef2f2;
    color: #991b1b;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.Toastify__toast--warning {
    background-color: #fffbeb;
    color: #92400e;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.Toastify__toast-body {
    margin: 0;
}
