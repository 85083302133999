.embla {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    --slide-size: 100%;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    display: flex;
}

.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    /* min-width: 0; */
}

.embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}

.embla__dots {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.embla__dot {
    appearance: none;
    background-color: #757575;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 6px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem #757575;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.embla__dot--selected {
    width: 20px;
    border-radius: 23px;
    background-color: #00c853;
    transition: all 200ms ease-in-out;
}

.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem #00c853;
}

@media (max-width: 426px) {
    .embla__controls {
        margin-top: 8px;
    }
}
