.lens_embla {
    max-width: fit-content;
    height: 100%;
    margin: auto;
    --slide-height: 90%;
    --slide-spacing: 1.2rem;
    --slide-size: 100%;
}

.lens_embla__viewport {
    overflow: hidden;
    height: 100%;
}

.lens_embla__container {
    display: flex;
    touch-action: pan-x pinch-zoom;
    margin-top: calc(var(--slide-spacing) * -1);
    height: calc(var(--slide-spacing) + var(--slide-height));
    flex-direction: column;
}

.lens_embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-height: 0;
    padding-top: var(--slide-spacing);
}

.lens_embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    user-select: none;
}
