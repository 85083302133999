.shade_embla {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 2rem;
    --slide-size: 50%;
}

.shade_embla__viewport {
    overflow: hidden;
}

.shade_embla__container {
    display: flex;
    max-width: 100%;
}

.shade_embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    max-width: 540px;
    height: 540px;
    padding-left: var(--slide-spacing);
}

.shade_embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    margin-top: 1.8rem;
}

.shade_embla__dots {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.shade_embla__dot {
    appearance: none;
    background-color: #757575;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 6px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.shade_embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem #757575;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.shade_embla__dot--selected {
    width: 20px;
    border-radius: 23px;
    /* background-color: #00c853; */
    background-color: #FF6EC7;
    transition: all 300ms ease-in-out;
}

.shade_embla__dot--selected:after {
    /* box-shadow: inset 0 0 0 0.2rem #00c853; */
    box-shadow: inset 0 0 0 0.2rem #FF6EC7;
}

@media (max-width: 426px) {
    .shade_embla__slide {
        max-width: 220px !important;
        height: 220px !important;
    }

    .shade_embla {
        --slide-spacing: 1rem !important;
    }
}
