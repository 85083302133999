@font-face {
  font-family: 'Urbanist';
  src: url('./Fonts/Urbanist-Regular.ttf') format('truetype'),
}
@font-face {
  font-family: 'mulish';
  src: url('./Fonts/Mulish-Regular.ttf') format('truetype');
}
a{
  text-decoration: none;
}
body{
  font-family: "Urbanist", Helvetica;
}
