@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Urbanist-Regular";
  src: url("./Fonts/Urbanist-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mulish-Regular";
  src: url("./Fonts/Mulish-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Urbanist-Regular" -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Urbanist-Regular", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.font-urbanist-regular {
  font-family: "Urbanist-Regular";
}

.font-mulish {
  font-family: "Mulish";
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-y-90 {
  transform: rotateY(90deg);
}

.rotate-y-0 {
  transform: rotateY(0deg);
}

.text-display {
  @apply text-display-text leading-display-text font-bold;
}

.text-heading1 {
  @apply text-heading-1 leading-heading-1 font-bold;
}

.text-heading2 {
  @apply text-heading-2 leading-heading-2 font-semibold;
}

.text-heading3 {
  @apply text-heading-3 leading-heading-3 font-semibold;
}

.text-title1 {
  @apply text-title-1 leading-title-1 font-semibold;
}

.text-title2 {
  @apply text-title-2 leading-title-2 font-semibold;
}

.text-title3 {
  @apply text-title-3 leading-title-3 font-semibold;
}

.text-label1 {
  @apply text-label-1 leading-label-1 font-semibold;
}

.text-label2 {
  @apply text-label-2 leading-label-2 font-medium;
}

.text-label3 {
  @apply text-label-3 leading-label-3 font-medium;
}

.text-body1 {
  @apply text-body-1 leading-body-1 font-normal;
}

.text-body2 {
  @apply text-body-2 leading-body-2 font-normal;
}

.text-body3 {
  @apply text-body-3 leading-body-3 font-normal;
}

.hover-transition {
  @apply transition-all duration-200 ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* page Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  position: relative;
  border-radius: 1px;
  background-color: #E3E3E3;
}

::-webkit-scrollbar-thumb:hover {
  background: #E3E3E3;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #E3E3E3 transparent;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 19px;
  background-color: #E3E3E3;
}

*::-webkit-scrollbar-thumb:hover {
  background: #E3E3E3;
}
